html,
body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#chatContent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

#chatContent::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
}

#chatContent::-webkit-scrollbar-thumb {
    background-color: #264653;
    border: 2px solid #555555;
}

.MuiDialogContent-root::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

.MuiDialogContent-root::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
    background-color: #264653;
    border: 2px solid #555555;
}
.ant-notification,
.ant-message {
    z-index: 99999 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
